
  .container-browser{
    width: 100% !important;    
    max-width: 70% !important;    
    margin: auto;         
  }

  .container-mobile{
    width: 100% !important;    
    max-width: 100% !important; 
    margin: auto;      
  }

  .avatar{
    height: 30px;
    margin: 10px;
  }

 