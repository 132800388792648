.dialog-content-holder {
    position: relative;
    height: 100%;

    .comments-container {
        display: flex;
        flex-direction: column;
        height: 520px;
        flex: 1;
        overflow-y: auto;
    }

    .content-footer {
        position: absolute;
        bottom: 0;
        width: 100%;

        .next-button {
            width: 50px;
        }
    }
    .add-point-form {
        min-height: 20px;
        .validation-feedback {
            font-weight: 100;
            font-size: small;
        }
    }

    .comment-holder {
        background-color: white;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 10px;
        .comment-text {
            font-size: small;
            margin-bottom: 0;
            font-weight: 100;
        }
        .comment-date {
            font-size: small;
            margin-bottom: 0;
        }
    }

    .dialog-custom-contnet {
        .facility-box {
            display: flex;
            flex-direction: row;
            padding-bottom: 5px;
            width: 100%;
            position: relative;
            flex-wrap: wrap;
            border: solid;
            margin-bottom: 3px;
            border-radius: 10px;
            border-color: white;
            padding-top: 10px;
            padding: 10px;
            height: 100%;

            .facility-checkbox {
                flex: 0 0 33.333333%;
            }
        }
    }
    .upload-form {
        height: 500px;

        .crop-containter {
            position: relative;
            width: 100%;
            height: 70%;
        }
        .uploaded-list {
            font-size: small;
            padding: 20px;
            li {
                margin: 0;
            }
        }
    }
}
