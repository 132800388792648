.modal {
    .modal-content {
        background-color: #e9f5f7 !important;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700 !important;
        color: #366c56 !important;

        .modal-title-text {
            width: 100%;
            text-align: center;
        }

        .modal-body {
            .form-control {
            }
            .form-select {
                color: #366c56 !important;
            }

            .empty-content {
                min-height: 10px;
            }
            .add-point-logo-holder {
                position: absolute;
                bottom: 20px;
                .add-point-logo {                    
                    
                    width: 98%;
                }
            }
            .rating {
                padding: 30px 0px 0px 10px;
            }

            .uploadFile {
                padding: 10px 0px 0px 10px;
            }

            .point-icon {
                margin-top: 5px;
                margin-left: 5px;
                margin-right: 5px;
            }

            .btn-primary {
                font-family: "Roboto Condensed", sans-serif;
                font-weight: 700 !important;
                color: #366c56 !important;
                background-color: #e9f5f7 !important;
                border-color: #366c56 !important;
                float: right;
                margin-top: 30px;

                &:hover {
                    background-color: #cce6eb !important;
                }
            }
        }
    }
}
