.dialog-container {
    .dialog-content{
   
           
    }       
    .modal-content {
        background-color: #e9f5f7 !important;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700 !important;
        color: #366c56 !important;

     

        .modal-title-text {
            width: 100%;
            text-align: center;
        }

        .modal-body {
            .point-details-popup {
                
                .rating-box{
                    display: flex;
                    align-items: center;     
                    margin: 0;               
                    padding: 0;
                    min-height: 30px;                    

                    .voted-text{
                        font-size: small;   
                        margin-left: 5px;   
                        margin-top: 2px;
                    }

                    .rating {
                        padding: 0px 0px 00px 0px;
                        margin: 0;
                    }
                    .comments-icon{
                        height: 13px;
                        margin-left: 10px;
                        cursor: pointer;
                    }                   
                }
                .facility-icon {
                    .cbx-icon {
                        height: 16px;
                        width: 16px;
                    }
                }
                .point-description-box {
                    padding: 10px 0px 0px 0px;
                    min-height: 70px;
                    margin: 0;
                    p {
                        font-size: small;
                        font-weight: lighter;
                        margin: 0;
                        padding: 0;
                        
                    }
                }
                .facility-block{
                    padding: 0;
                    border-color: white;
                    min-height: 30px;
                }                
            }

            .btn-primary {
                font-family: "Roboto Condensed", sans-serif;
                font-weight: 700 !important;
                color: #366c56 !important;
                background-color: #e9f5f7 !important;
                border-color: #366c56 !important;
                float: right;
                font-size: small;
                padding: 3px 5px 3px 5px;
                margin-left: 5px;

                &:hover {
                    background-color: #cce6eb !important;
                }
            }
            .btn-secondary {
                
                border-color: #bcdacd !important;
                
            }
        }
    }
}
.delete-confirm-dialog{
    background-color: red;
    max-width: 400px !important;
    .btn-primary {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700 !important;
        color: #366c56 !important;
        background-color: #e9f5f7 !important;
        border-color: #366c56 !important;
        float: right;
        font-size: small;
        padding: 3px 5px 3px 5px;
        margin-left: 5px;

        &:hover {
            background-color: #cce6eb !important;
        }      
    }    
}

.alert-confirm-dialog{
    background-color: while;
    max-width: 400px !important;
    .btn-primary {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700 !important;
        color: #366c56 !important;
        background-color: #e9f5f7 !important;
        border-color: #366c56 !important;
        float: right;
        font-size: small;
        padding: 3px 5px 3px 5px;
        margin-left: 5px;
        width: 40px;

        &:hover {
            background-color: #cce6eb !important;
        }      
    }
    .btn-secondary {                
        border-color: #bcdacd !important;
        width: 60px !important;
    }
    .alert-text{
        justify-content: center;
        display: flex;    
    }
}

.btn-secondary {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700 !important;
    color: #366c56 !important;
    background-color: #fff !important;
    border-color: #e4f0eb !important;
    float: right;
    font-size: small !important;
    padding: 3px 5px 3px 5px;
    margin-left: 5px;

    &:hover {
        background-color: #f1f8fa !important;
    }
}