.googleText{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700!important;
    color: #366c56!important;
    font-stretch: ultra-condensed;
    
    a{
        color: #366c56!important;
        margin-top: 15px;
    }
}