.dialog-container{
    .dialog-header{
        padding: 7px;
        border:solid;
        border-color: white;
        border-radius: 5px;
    }
    .dialog-content{
        height: 650px;              
    }     
      
}