.facility-block {
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
    width: 100%;
    border-top: solid;
    border-width: 1px;
    flex-wrap: wrap;

    .facility-icon {
        margin: 5px;        
    }
    .cbx-icon {
        height: 25px;
        width: 25px;
        // padding: 7px;
    }
}

.facility-checkbox {

    position: relative;
    display: flex;
    flex-direction: row;


    .cbx-col-lg {
        padding: 5px !important;
    }

    .cbx-label {
        font-size: 13px;
    }

    .cbx-icon {
        height: 25px;
        width: 25px;
        // padding: 7px;
    }
}
