.navBar{
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    border-bottom: solid 1px;
    border-color: green;

    .logoNavBar{
        height: 65px;
    }

    .navBarLogo{
        padding-top: 0px!important;
        padding-bottom: 0px!important;
    }
    
    .navBarLink{
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700!important;
        color: #366c56!important;
        font-size: 22px!important;
        font-stretch: ultra-condensed;
    
        &:hover {
            background-color: #e9f5f7!important;
        }
    }
    
    .navBarButton{
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700!important;
        color: #366c56!important;
        font-stretch: ultra-condensed;
        
        height: 30px;
    
        &:hover {
            background-color: #e9f5f7!important;
        }
    }
    
    .d-flex {
        align-self: center;
        height: 30px;
        padding-left: 200px;
    }

    .auth-block{
        display: flex;
        flex-direction: row;
    }
}

